import React from "react";
import Img from "gatsby-image";
import useGetImages from "./useGetImages";
import styles from "./Gallery.module.scss";

const Gallery = () => {
  const nodes = useGetImages();
  return (
    <div className={styles.gallery}>
      {nodes.map(image => {
        return (
          <Img
            className={styles.image}
            key={image.childImageSharp.fluid.src}
            alt="gallery"
            fluid={image.childImageSharp.fluid}
          />
        );
      })}
    </div>
  );
};

export default Gallery;
