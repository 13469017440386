import { useStaticQuery, graphql } from "gatsby";

export const GalleryImage = graphql`
  fragment GalleryImage on File {
    childImageSharp {
      fluid(maxWidth: 1500, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
const useGetImages = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "images/webGallery" } }
        sort: { fields: [name], order: ASC }
        ) {
        nodes {
          relativePath
          ...GalleryImage
        }
      }
    }
  `);

  return nodes;
};
export default useGetImages;
