import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      scope: file(
        relativePath: { eq: "pdf/A2L-Scope.pdf" }
      ) {
        publicURL
      }
      certificate: file(
        relativePath: { eq: "pdf/A2LA-Certificate.pdf" }
      ) {
        publicURL
      }
    }
  `)
  return data
}
