import React from "react";
import Content from "./components/content";
import styles from "./Test.module.scss";

const Test = () => (
  <div className={styles.wrap}>
    <div className={styles.container}>
      <Content />
    </div>
  </div>
);
export default Test;
