import React from "react";
import Test from "../scenes/test";
import SEO from "../components/seo";

export default () => {
  return (
    <>
      <SEO title={"Lab"} />
      <Test />
    </>
  );
};
